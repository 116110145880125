import React from "react";

const About = () => {
  return (
    <div className="min-h-screen flex flex-col bg-sunset">
      <div className="flex flex-col items-start text-federal-blue flex-grow ml-[5vw] sm:ml-[20vw] md:ml-[15vw] lg:ml-[20vw]">
        <h1 className="text-4xl font-bold mb-6 mt-[20vw] md:mt-[15vw] lg:mt-[8vw] text-left font-georgia">
        Born from Love, Grounded in Purpose
        </h1>
          <section className="mb-3 font-courier"> {/* Fixed margin */}
            <p className="text-lg">
              Solestielle comes from the names of my family:
              <strong> Soleil</strong>, <strong> Celeste</strong>, 
              <strong> Estelle</strong>.
              <br />
              And they are the foundation.
              <br /><br />
              These are the ideas that emerged at dinner tables after bad work days and good ones. 
              <br />Calls from old friends and missed calls from dad.
              <br/>Blindsiding breakups
              and rekindled passions.
              <br /><br />
              This is a tribute to life's gifts & tribulations and<br/> the opportunity we have to navigate them all <u>together</u>.
            </p>
          </section>

          <div className="border-t border-federal-blue w-[50vw] my-6"></div>
        </div>
      </div>
  );
};

export default About;

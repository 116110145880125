import React, { useState, useEffect, useRef } from 'react';

function ADAAccessibilityModal({ textColor }) {
  const [isOpen, setIsOpen] = useState(false);
  const closeButtonRef = useRef(null); // Ref for the close button

  // Close modal on Escape key press
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'Escape') {
        setIsOpen(false);
      }
    };
    if (isOpen) {
      document.addEventListener('keydown', handleKeyDown);
    } else {
      document.removeEventListener('keydown', handleKeyDown);
    }

    return () => document.removeEventListener('keydown', handleKeyDown);
  }, [isOpen]);

  // Set focus on the close button when modal is open
  useEffect(() => {
    if (isOpen && closeButtonRef.current) {
      closeButtonRef.current.focus();
    }
  }, [isOpen]);

  return (
    <>
      <button
        type="button"
        onClick={() => setIsOpen(true)}
        className={`${textColor} hover:underline focus:outline-none`}
        aria-haspopup="dialog"
        aria-expanded={isOpen}
      >
        Accessibility Statement
      </button>

      {isOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4"
          role="dialog"
          aria-labelledby="accessibility-statement-title"
          aria-modal="true"
        >
          <div className="bg-white rounded-lg p-6 max-w-md w-full">
            <h2
              id="accessibility-statement-title"
              className="text-lg font-medium mb-4 text-left"
            >
              <strong>Accessibility Statement</strong>
            </h2>
            <div className="mb-4 text-left">
              <p>Effective Date: October 1, 2024</p>
              <p className="mt-2">
                We are committed to ensuring digital accessibility for people with disabilities. 
                We are continually improving the user experience for everyone, and applying the relevant accessibility standards.
                If you have any concerns or feedback, please reach out to <strong>hello@solestielle.com</strong>.
              </p>
            </div>
            <div className="text-right">
              <button
                ref={closeButtonRef}
                type="button"
                className="bg-federal-blue text-white px-4 py-2 rounded-md hover:bg-opacity-80 focus:outline-none focus:ring-2 focus:ring-federal-blue"
                onClick={() => setIsOpen(false)}
                aria-label="Close Accessibility Statement"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default ADAAccessibilityModal;

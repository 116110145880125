import React from 'react';
import PrivacyPolicyModal from './PrivacyPolicyModal';
import ADAAccessibilityModal from './ADAAccessibilityModal';
import TermsOfService from './TermsOfService';

function FooterWithLinks({ textColor = 'text-navajo-white' }) {
  const currentYear = new Date().getFullYear();

  return (
    <footer className={`mt-auto w-full text-center py-4`} role="contentinfo" aria-label="Site Footer">
      <p className={`text-xs ${textColor}`} tabIndex="0"> {/* Added tabIndex for screen readers */}
        © {currentYear} Solestielle LLC. All rights reserved.
      </p>
      <nav aria-label="Footer navigation" className="flex justify-center space-x-4 mt-2 text-xs">
        <PrivacyPolicyModal textColor={textColor} aria-label="Open Privacy Policy" />
        <TermsOfService textColor={textColor} aria-label="Open Terms of Service" />
        <ADAAccessibilityModal textColor={textColor} aria-label="Open Accessibility Statement" />
      </nav>
    </footer>
  );
}

export default FooterWithLinks;
